@tailwind base;
@tailwind components;
@tailwind utilities;

.nav-toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 22px;
    width: 22px;
  }
  
  .nav-toggle .bar {
    height: 3px;
    width: 100%;
    background-color: black;
    transition: all 100ms ease-in-out;
  }
  
  .nav-toggle:hover {
    cursor: pointer;
  }
  
  .x:nth-of-type(1) {
    transition: all 100ms ease-in-out;
    transform: rotate(45deg);
    transform-origin: top left;
    width: 28px;
  }
  
  .x:nth-of-type(2) {
    transition: all 100ms ease-in-out;
    transform-origin: center;
    width: 0;
  }
  
  .x:nth-of-type(3) {
    transition: all 100ms ease-in-out;
    transform: rotate(-45deg);
    transform-origin: bottom left;
    width: 28px;
  }

.navlink-active {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}